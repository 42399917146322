/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { kpx, sans_serif_font, flex_center } from './utils.js';
import { PostIcon } from './post_icon.js';

export class UserSnap {
  view() {
    const style1 = {
      with: '100%',
      height: 'calc(183 / 375 * 100vw)',
      backgroundColor: 'brack',
    };
    const style2 = {
      textAlign: 'center',
      color: 'white',
      ...sans_serif_font(),
      ...flex_center(),
      'align-items': 'flex-end',
      'height': kpx(65),
    };
    const style3 = {
      ...style2,
      'height': kpx(25),
    };
    const style4 = {
      ...style2,
      'height': kpx(90),
      'align-items': 'flex-start',
    };
    return m('div', {style: style1}, [
      m('div', {style: style2}, 'USER SNAP'),
      m('div', {style: style3}, 'お店に訪れた写真をシェアしよう!'),
      m('div', {style: style4}, [
        m(PostIcon),
      ]),
    ]);
  }
}

/* EOF */
