/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import {text_wrap, kpx, serif_font, sans_serif_font} from './utils.js';
import {CloseButton} from './close_button.js';
import {LogoIcon} from './logo_icon.js';

export class SearchResults {
  view() {
    const shops = window.search_results || [];

    // 管理しやすいようにスタイルをオブジェクトに纏めておく
    const css = {};
    css.base1 = {
      'align-items': `center`,
      'background-color': `rgba(69, 70, 70, 1.0)`,
      'box-sizing': `border-box`,
      'display': `flex`,
      'flex-direction': `column`,
      'height': `100%`,
      'justify-content': `flex-start`,
      'opacity': `0.96`,
      'overflow': `hidden`,
      'overflow-x': `hidden`,
      'padding': `${kpx(24)} ${kpx(23)}`,
      'position': `relative`,
      'width': `${kpx(375)}`,
      'pointer-events': `auto`,
      'flex-shrink': `0`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
    };
    css.text1 = {
      ...sans_serif_font(),
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'flex-shrink': `1`,
      'font-size': `${kpx(16)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'height': `auto`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(25)}`,
      'margin-bottom': `${kpx(-7)}`,
      'margin-left': `${kpx(4.0)}`,
      'margin-top': `${kpx(64)}`,
      'min-height': `${kpx(18)}`,
      'min-width': `${kpx(66)}`,
      'position': `relative`,
      'text-align': `center`,
      'white-space': `nowrap`,
      'width': `auto`,
    };
    css.img1 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'flex-shrink': `1`,
      'height': `${kpx(1)}`,
      'margin-left': `${kpx(1.0)}`,
      'margin-top': `${kpx(17)}`,
      'position': `relative`,
      'width': `${kpx(281)}`,
    };
    css.base2 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'flex-shrink': `1`,
      'height': `${kpx(79)}`,
      'margin-left': `${kpx(4.0)}`,
      'margin-top': `${kpx(32)}`,
      'position': `relative`,
      'width': `${kpx(274)}`,
    };
    css.text2 = {
      ...sans_serif_font(),
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'font-size': `${kpx(20)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'height': `${kpx(50)}`,
      'left': `${kpx(7)}`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(25)}`,
      'position': `absolute`,
      'text-align': `center`,
      'top': `${kpx(-2)}`,
      'width': `calc(100.00% - 12px)`,
    };
    css.text3 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
      'font-size': `${kpx(16)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'bottom': `${kpx(2)}`,
      'height': `${kpx(18)}`,
      'left': `${kpx(0)}`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(25)}`,
      'position': `absolute`,
      'text-align': `center`,
      'white-space': `nowrap`,
      'width': `calc(100.00% + 2px)`,
    };
    css.img2 = {
      "pointer-events": `auto`,
      "word-wrap": `break-word`,
      "word-break": `break-word`,
      "box-sizing": `border-box`,
      "background-color": `transparent`,
      "flex-shrink": `1`,
      "height": `${kpx(1)}`,
      "margin-left": `${kpx(1.0)}`,
      "margin-top": `${kpx(62)}`,
      "position": `relative`,
      "width": `${kpx(26)}`
    };
    css.base3 = {
      position: 'relative',
      width: '100%',
      height: '80vh',
      'overflow-y': 'scroll',
    };

    const results = [];
    let index = 0;
    for (const shop of shops) {
      results.push(
        m('div', {
          style: css.base2,
          onclick: (e) => {
            window.loaded = false;
            m.redraw();
            document.getElementById('search-results').hide();
            document.location = `/app/shop/${shop.ulid}/`;
          }
        }, [
          m('div', {style: css.text2}, [
            shop.title,
            m('br'),
            shop.name,
          ]),
          m('div', {style: css.text3}, [
            shop.name_en,
          ]),
        ])
      );
      index++;
      if (index < shops.length) {
        results.push(
          m('img', {style: css.img2, src: '/static/app/img/file--29@1x.png'})
        );
      }
    }

    return m('ons-modal', {id: 'search-results'}, [
      m('.modal__content', {style: css.base1}, [
        m(CloseButton),
        m('div', {style: css.text1}, '検索結果'),
        m('img', {style: css.img1, src: '/static/app/img/file--28@1x.png'}),
        m('div', {style: css.base3}, [
          results,
        ])
      ]),
    ]);
  }
}

/* EOF */
