/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';

export class PostIcon {
  view() {
    const factor = window.innerWidth / 375;
    return m('svg', {
      'xmlns': 'http://www.w3.org/2000/svg',
      'width': `${32.2 * factor}`,
      'height': `${32.2 * factor}`,
      'viewBox': '0 0 32.2 32.2',
    }, [
      m('g', {
        'id': 'グループ_427',
        'data-name': 'グループ 427',
        'transform': 'translate(1708.6 -2550.9)',
      }, [
        m('g', {'id': 'グループ_572', 'data-name': 'グループ 572'}, [
          m('g', {'id': 'グループ_573', 'data-name': 'グループ 573'}, [
            m('rect', {
              'id': '長方形_229',
              'data-name': '長方形 229',
              'width': '31',
              'height': '31',
              'rx': '2.749',
              'transform': 'translate(-1708 2551.5)',
              'fill': 'none',
              'stroke': '#fff',
              'stroke-miterlimit': '10',
              'stroke-width': '1.2',
            }),
            m('circle', {
              'id': '楕円形_18',
              'data-name': '楕円形 18',
              'cx': '6.715',
              'cy': '6.715',
              'r': '6.715',
              'transform': 'translate(-1699 2560.5)',
              'fill': 'none',
              'stroke': '#fff',
              'stroke-miterlimit': '10',
              'stroke-width': '1.2',
            }),
            m('g', {
              'id': 'グループ_426',
              'data-name': 'グループ 426',
              'transform': 'translate(0.335 -0.165)',
            }, [
              m('path', {
                'id': 'パス_335',
                'data-name': 'パス 335',
                'd': 'M160.214,46.741V42.173h4.568',
                'transform': 'translate(-1865.049 2512.992)',
                'fill': 'none',
                'stroke': '#fff',
                'stroke-miterlimit': '10',
                'stroke-width': '1.2',
              }),
              m('path', {
                'id': 'パス_336',
                'data-name': 'パス 336',
                'd': 'M173.051,42.173h4.568v4.568',
                'transform': 'translate(-1859.349 2512.992)',
                'fill': 'none',
                'stroke': '#fff',
                'stroke-miterlimit': '10',
                'stroke-width': '1.2',
              }),
              m('path', {
                'id': 'パス_337',
                'data-name': 'パス 337',
                'd': 'M177.619,55.01v4.568h-4.568',
                'transform': 'translate(-1859.349 2518.692)',
                'fill': 'none',
                'stroke': '#fff',
                'stroke-miterlimit': '10',
                'stroke-width': '1.2',
              }),
              m('path', {
                'id': 'パス_338',
                'data-name': 'パス 338',
                'd': 'M164.782,59.578h-4.568V55.01',
                'transform': 'translate(-1865.049 2518.692)',
                'fill': 'none',
                'stroke': '#fff',
                'stroke-miterlimit': '10',
                'stroke-width': '1.2',
              }),
            ]),
          ]),
        ]),
      ]),
    ]);
  }
}

/* EOF */

