/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';

export class MenuIcon {
  view(vnode) {
    const width = vnode.attrs.width ? vnode.attrs.width : 24;
    const height = vnode.attrs.height ? vnode.attrs.height : 16;
    return m("svg", {
      "xmlns":"http://www.w3.org/2000/svg",
      'width': `${width}`,
      'height': `${height}`,
      "viewBox":"0 0 24 16"
    }, [
      m("path", {
        "id":"パス_324",
        "data-name":"パス 324",
        "d":"M78,36h24M78,28.5h24m-24,15h24",
        "transform":"translate(-78 -28)",
        "fill":"#0b0338",
        "stroke":"#231815",
        "stroke-miterlimit":"10",
        "stroke-width":"1"
      })
    ]);
  }
}

/* EOF */
