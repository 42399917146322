/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';

export class CloseIcon {
  view(vnode) {
    const stroke = vnode.attrs.stroke ? vnode.attrs.stroke : '#f3f3f2';
    return m('svg',
      {
        'xmlns': 'http://www.w3.org/2000/svg',
        'width': '22',
        'height': '22',
        'viewBox': '0 0 22 22',
      }, [
        m('g', {
          'id': 'グループ_411',
          'data-name': 'グループ 411',
          'transform': 'translate(1943 -413)',
        }, [
          m('g', {'id': 'グループ_410', 'data-name': 'グループ 410'}, [
            m('line', {
              'id': '線_8',
              'data-name': '線 8',
              'x2': '17',
              'y2': '17',
              'transform': 'translate(-1940.5 415.5)',
              'fill': 'none',
              'stroke': stroke,
              'stroke-width': '1',
            }),
            m('line', {
              'id': '線_9',
              'data-name': '線 9',
              'y1': '17',
              'x2': '17',
              'transform': 'translate(-1940.5 415.5)',
              'fill': 'none',
              'stroke': stroke,
              'stroke-width': '1',
            }),
          ]),
          m('rect', {
            'id': '長方形_224',
            'data-name': '長方形 224',
            'width': '22',
            'height': '22',
            'transform': 'translate(-1943 413)',
            'fill': 'none',
          }),
        ]),
      ]);
  }
}

/* EOF */
