/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';

export class Loading {
  view(vnode) {
    const display = vnode.attrs.loaded ? 'none' : 'block';
    const style = {
      display: display,
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      'background-color': '#000',
      opacity: '0.8',
      'z-index': '99999',
    };
    return m('div', {style: style}, [
      m('div', {
        style: {
          'margin-top': '15rem',
          'text-align': 'center',
          'color': 'white',
        },
      }, [
        m('ons-icon', {icon: 'md-spinner', size: '32px', spin: true}),
        m('span', {style: {'font-size': '32px', 'margin-left': '2rem'}},
          'ロード中...'),
      ]),
    ]);
  }
}

/* EOF */
