/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { Panel } from './panel.js';

export class Posts {
  view(vnode) {
    const style = {
      display: 'grid',
      'grid-template-columns': 'calc(1 / 3 * 100vw) calc(1 / 3 * 100vw) calc(1 / 3 * 100vw)',
    };
    const top = vnode.attrs.top;
    let post_panels = vnode.attrs.post_panels;
    let last = null;
    if (post_panels.length > 1) {
      last = post_panels.slice(-1)[0];
      if (last.kind === 'O') {
        post_panels = vnode.attrs.post_panels.slice(0, -1);
      }
      else {
        last = null;
      }
    }
    const size = post_panels.map((s) => s.size).reduce((a, v) => a + v, 0);
    const pad_num = size % 3 == 0 ? 0 : 3 - size % 3;
    const padding = [...Array(pad_num).keys()].map(() => {
      return {kind: '', size: 1};
    });
    return m('div', {style}, [
      post_panels.map((panel) => {
        return m(Panel, {panel});
      }),
      padding.map((panel) => {
        return m(Panel, {panel});
      }),
      (last ? [ last ] : []).map((panel) => m(Panel, {panel, top})),
    ]);
  }
}

/* EOF */
