/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { Page } from 'mithril-onsenui';
import { Topbar } from './topbar.js';
import { UserSnap } from './user_snap.js';
import { Posts } from './posts.js';
import { Footer } from './footer.js'
import { About } from './about.js';
import { ShopDetail} from './shop_detail.js';
import { SearchResults} from './search_results.js';
import { Loading } from './loading.js';
import { dummy_image, get_headers } from './utils.js';

export class ShopPage {
  constructor() {
    window.loaded = false;
    this.shop = {};
    this.post_panels = [];
  }

  oninit() {
    // 問い合わせ文字列からULIDを得る
    const qs = new URLSearchParams(document.location.search);
    const ulid = qs.get('ulid');

    const headers = get_headers();
    m.request({method: 'GET', url: `/api/shops/${ulid}/`, headers}).
      then((res) => {
        this.shop = res;
        return m.request({method: 'GET', url: `/api/panels/posts/?shop=${ulid}`, headers});
      }).
      then((res) => {
        this.post_panels = res;
        // FIXME:まだデータが入っていないので仮の画像を補う
        for (const panel of this.post_panels) {
          if (panel.kind === 'h') {
            if (!panel.post_photo || !panel.post_photo.image) {
              panel.post_photo = {image: dummy_image()};
            }
          }
        }
        window.loaded = true;
      });
  }

  view(vnode) {
    return m('div', [
      m(Page, [
        m(Topbar),
        m('.page__background', {
          style: {
            top: '6rem', 'background-color': 'black'
          }
        }),
        m('.page__content', {style: {top: '6rem'}}, [
          m(ShopDetail, {shop: this.shop}),
          m(UserSnap),
          m(Posts, {post_panels: this.post_panels}),
          m(Footer),
        ]),
        m(Loading, {loaded: window.loaded}),
      ]),
      m(About),
      m(SearchResults),
    ]);
  }
}

/* EOF */
