/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { text_wrap, sans_serif_font, serif_font, kpx,flex_center, get_headers } from './utils.js';
import { SearchIcon } from './search_icon.js';
import { CloseButton } from './close_button.js';

export class About {
  search(keyword) {
    // 画面をロード状態にしてアバウト画面を非表示にする
    window.loaded = false;
    m.redraw();
    document.getElementById('about').hide();

    const headers = get_headers();
    const params = {keyword};
    m.request({method: 'GET', url: '/api/shops/search/', params, headers}).
      then((res) => {
        console.dir(res);
        window.search_results = res;

        // ロード中を非表示にして検索結果を表示する
        window.loaded = true;
        m.redraw();
        document.getElementById('search-results').show();
      });
  }

  view(vnode) {
    // ABOUTのスタイル
    const style1 = {
      ...text_wrap(),
      ...sans_serif_font(),
      'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
      'font-size': kpx(26),
      'letter-spacing': '0.00px',
      'line-height': kpx(25),
      'text-align': 'center',
      'white-space': 'nowrap',
      'background-color': 'transparent',
      'color': 'rgba(243,243,242,1.0)',
      'position': 'absolute',
      'left': kpx(59),
      'top': kpx(110),
      'width': kpx(260),
      'height': kpx(38),
    };
    // AREAのスタイル
    const style2 = {
      ...style1,
      'font-size': kpx(16),
      'left': kpx(145),
      'top': kpx(182),
      'width': kpx(88),
      'height': kpx(25),
    };
    // 最初の'｜'のスタイル
    const style3 = {
      ...style1,
      'font-size': kpx(15),
      'left': kpx(0),
      'top': kpx(210),
      'height': kpx(15),
      'width': kpx(375),
    };
    // NIHONBASHIのスタイル
    const style4 = {
      ...style1,
      'font-size': kpx(14),
      'top': kpx(236),
      'left': kpx(54),
      'width': kpx(136),
      'height': kpx(19),
    };
    // NINGYOCHOのスタイル
    const style5 = {
      ...style1,
      'font-size': kpx(14),
      'height': kpx(19),
      'left': kpx(188),
      'top': kpx(236),
      'width': kpx(136),
    };
    // '日本橋'のスタイル
    const style6 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(20),
      'top': kpx(266),
      'left': kpx(91),
      'width': 'auto',
      'height': 'auto',
    };
    // '人形町'のスタイル
    const style7 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(20),
      'top': kpx(266),
      'left': kpx(225),
      'width': 'auto',
      'height': 'auto',
    };
    // 'CATEGORY'のスタイル
    const style8 = {
      ...style1,
      'font-size': kpx(16),
      'height': kpx(25),
      'left': kpx(145),
      'overflow': 'hidden',
      'top': kpx(366),
      'width': kpx(88),
    };
    // 二つ目の'｜'のスタイル
    const style9 = {
      ...style1,
      'height': kpx(15),
      'left': kpx(0),
      'top': kpx(394),
      'width': kpx(375),
    };
    // 'JAPANESE'のスタイル
    const style10 = {
      ...style1,
      'font-size': kpx(12),
      'top': kpx(412),
      'left': kpx(65),
      'width': kpx(114),
      'height': kpx(14),
    };
    // 'CHINESE'のスタイル
    const style11 = {
      ...style1,
      'font-size': kpx(12),
      'height': kpx(14),
      'left': kpx(199),
      'top': kpx(412),
      'width': kpx(114),
    };
    // '日本料理・鮨'のスタイル
    const style12 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(16),
      'top': kpx(432),
      'left': kpx(82),
      'width': 'auto',
      'height': 'auto',
    };
    // '中国料理'のスタイル
    const style13 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(16),
      'top': kpx(434),
      'left': kpx(223),
      'width': 'auto',
      'height': 'auto',
    };
    // 'ITALIAN'のスタイル
    const style14 = {
      ...style1,
      'font-size': kpx(12),
      'top': kpx(470),
      'left': kpx(65),
      'width': kpx(114),
      'height': kpx(14),
    };
    // 'FRENCH'のスタイル
    const style15 = {
      ...style1,
      'font-size': kpx(12),
      'left': kpx(199),
      'top': kpx(470),
      'width': kpx(114),
      'height': kpx(14),
    };
    // 'イタリアン'のスタイル
    const style16 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(16),
      'left': kpx(82),
      'top': kpx(492),
      'width': 'auto',
      'height': 'auto',
    };
    // 'フレンチ'のスタイル
    const style17 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(16),
      'top': kpx(492),
      'left': kpx(223),
      'width': 'auto',
      'height': 'auto',
    };
    // 'WESTAN FOOD / GRILL'のスタイル
    const style18 = {
      ...style1,
      'font-size': kpx(12),
      'left': kpx(39),
      'top': kpx(530),
      'width': kpx(166),
      'height': kpx(14),
    };
    // 'NOODLE'のスタイル
    const style19 = {
      ...style1,
      'font-size': kpx(12),
      'top': kpx(530),
      'left': kpx(199),
      'width': kpx(114),
      'height': kpx(14),
    };
    // '洋食・グリル'のスタイル
    const style20 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(16),
      'top': kpx(552),
      'left': kpx(73),
      'width': 'auto',
      'height': 'auto',
    };
    // '麺'のスタイル
    const style21 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(16),
      'top': kpx(552),
      'left': kpx(247),
      'width': 'auto',
      'height': 'auto',
    };
    // 'SWEETS'のスタイル
    const style22 = {
      ...style1,
      'font-size': kpx(12),
      'top': kpx(588),
      'left': kpx(65),
      'width': kpx(114),
      'height': kpx(14),
    };
    // 'BAR'のスタイル
    const style23 = {
      ...style1,
      'font-size': kpx(12),
      'top': kpx(588),
      'left': kpx(199),
      'width': kpx(114),
      'height': kpx(14),
    };
    // '甘味'のスタイル
    const style24 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(16),
      'top': kpx(610),
      'left': kpx(105),
      'width': 'auto',
      'height': 'auto',
    };
    // 'バー'のスタイル
    const style25 = {
      ...style1,
      ...sans_serif_font(),
      'font-size': kpx(16),
      'left': kpx(239),
      'top': kpx(610),
      'width': 'auto',
      'height': 'auto',
    };
    // サーチバーのスタイル
    const style26 = {
      ...style1,
      ...sans_serif_font(),
      'background-color': 'rgba(255,255,255,1.0)',
      'top': kpx(697),
      'left': kpx(49),
      'width': kpx(278),
      'height': kpx(58),
    };
    const style27 = {
      ...style1,
      'font-size': '16px',
      color: 'black',
      'text-align': 'left',
      'padding-left': '1rem',
      'background-color': 'rgba(255,255,255,1.0)',
      'top': kpx(0),
      'left': kpx(0),
      'width': kpx(278),
      'height': kpx(58),
      '-webkit-appearance': 'none',
      'border-radius': 0,
    };
    const style28 = {
      ...flex_center(),
      'position': 'absolute',
      'top': kpx(0),
      'right': kpx(0),
      'width': kpx(58),
      'height': kpx(58),
    };
    const style29 = {
      'text-align': 'left',
      'display': 'block',
      'background-color': 'rgba(69,70,70,1.0)',
      'opacity': '0.96',
    };
    const style30 = {
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      overflowY: 'scroll',
    }
    const style31 = {
      ...style1,
      'top': kpx(755),
      'left': kpx(49),
      'width': kpx(278),
      'height': kpx(58),
    }

    return m('ons-modal', {id: 'about'}, [
      m('.modal__content', {style: style29}, [
        m('div', {style: style30}, [
          m(CloseButton),
          m('div', {style: style1}, 'ABOUT'),
          m('div', {style: style2}, 'AREA'),
          m('div', {style: style3}, '｜'),
          m('div', {style: style4}, 'NIHONBASHI'),
          m('div', {style: style5}, 'NINGYOCHO'),
          m('div', {style: style6}, '日本橋'),
          m('div', {style: style7}, '人形町'),
          m('div', {style: style8}, 'CATEGORY'),
          m('div', {style: style9}, '｜'),
          m('div', {style: style10}, 'JAPANESE'),
          m('div', {style: style11}, 'CHINESE'),
          m('div', {style: style12}, '日本料理・鮨'),
          m('div', {style: style13}, '中国料理'),
          m('div', {style: style14}, 'ITALIAN'),
          m('div', {style: style15}, 'FRENCH'),
          m('div', {style: style16}, 'イタリアン'),
          m('div', {style: style17}, 'フレンチ'),
          m('div', {style: style18}, 'WESTAN FOOD / GRILL'),
          m('div', {style: style19}, 'NOODLE'),
          m('div', {style: style20}, '洋食・グリル'),
          m('div', {style: style21}, '麺'),
          m('div', {style: style22}, 'SWEETS'),
          m('div', {style: style23}, 'BAR'),
          m('div', {style: style24}, '甘味'),
          m('div', {style: style25}, 'バー'),
          m('div', {style: style26}, [
            m('input', {
              type: 'search',
              style: style27,
              placeholder: '日本橋　やまや',
              onsearch: (e) => {
                this.search(e.target.value);
              }
            }),
            m('div', {style: style28}, m(SearchIcon)),
          ]),
          m('div', {style: style31}),
        ]),
      ]),
    ]);
  }
}

/* EOF */
