/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import {kpx, dummy_image, sans_serif_font} from './utils.js';

class ShopPhoto {
  view(vnode) {
    const shop = vnode.attrs.shop;
    const shopphoto_set = shop.shopphoto_set || [];
    let src = shopphoto_set[0] ? shopphoto_set[0].image : null;
    if (!src) {
      src = dummy_image();
    }
    const style = {
      position: 'relative',
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'width': kpx(375),
      'height': kpx(375),
    };
    return m('div', {style}, [
      m('img', {src, width: kpx(375)}),
    ]);
  }
}

class ShopInfo {
  constructor() {
    // more が有効な時にtrue
    this.more_active = false;
    // 全体の大きさ
    this.base_height = kpx(452);
  }

  view(vnode) {
    const shop = vnode.attrs.shop;
    const css = {};
    css.base1 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'flex-shrink': '1',
      'height': this.base_height,
      'margin-top': '0px',
      'position': 'relative',
      'width': kpx(375),
    };

    css.base2 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'rgba(69,70,70,1.0)',
      'height': this.base_height,
      'left': '0px',
      'position': 'absolute',
      'top': '0px',
      'width': kpx(375),
    };

    css.base3 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'height': kpx(86),
      'left': kpx(144),
      'position': 'absolute',
      'top': kpx(37),
      'width': kpx(86),
    };
    css.base4 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'background-image': 'url(/static/app/img/---346@1x.png)',
      'background-size': '100% 100%',
      'height': '100%',
      'left': '0px',
      'position': 'relative',
      'top': '0px',
      'width': '100%',
    };
    css.base5 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'height': kpx(29),
      'left': kpx(6),
      'position': 'relative',
      'top': `calc(48.25% - ${kpx(14)})`,
      'width': `calc(100.00% - ${kpx(13)})`,
    };
    css.text1 = {
      ...sans_serif_font(),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'bottom': kpx(6),
      'color': 'rgba(243,243,242,1.0)',
      'font-size': kpx(12),
      'font-style': 'normal',
      'font-weight': '400',
      'height': kpx(12),
      'left': kpx(6),
      'letter-spacing': '0.00px',
      'line-height': kpx(25),
      'position': 'absolute',
      'text-align': 'center',
      'white-space': 'nowrap',
      'width': `calc(100.00% - ${kpx(11)})`,
    };
    css.text2 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'color': 'rgba(243,243,242,1.0)',
      'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
      'font-size': kpx(12),
      'font-style': 'normal',
      'font-weight': '400',
      'height': kpx(14),
      'left': '0px',
      'letter-spacing': '0.00px',
      'line-height': kpx(25),
      'position': 'absolute',
      'text-align': 'center',
      'top': kpx(-8),
      'white-space': 'nowrap',
      'width': `calc(100.00% + ${kpx(2)})`,
    };
    css.text3 = {
      ...sans_serif_font(),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'color': 'rgba(243,243,242,1.0)',
      'font-size': kpx(24),
      'font-style': 'normal',
      'font-weight': '400',
      'height': kpx(35),
      'left': kpx(53),
      'letter-spacing': '0.00px',
      'line-height': kpx(22),
      'position': 'absolute',
      'text-align': 'center',
      'top': kpx(140),
      'width': kpx(271),
    };
    css.img1 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'height': kpx(1),
      'left': kpx(182),
      'position': 'absolute',
      'top': kpx(183),
      'width': kpx(13),
    };
    css.text4 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'color': 'rgba(243,243,242,1.0)',
      'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
      'font-size': kpx(14),
      'font-style': 'normal',
      'font-weight': '400',
      'height': kpx(20),
      'left': kpx(139),
      'letter-spacing': '0.00px',
      'line-height': kpx(25),
      'overflow': 'hidden',
      'position': 'absolute',
      'text-align': 'center',
      'top': kpx(188),
      'white-space': 'nowrap',
      'width': kpx(100),
    };
    css.text5 = {
      ...sans_serif_font(),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'color': 'rgba(243,243,242,1.0)',
      'font-size': kpx(18),
      'font-style': 'normal',
      'font-weight': '400',
      'height': 'auto',
      'left': kpx(44),
      'letter-spacing': '0.00px',
      'line-height': kpx(25),
      'position': 'absolute',
      'text-align': 'center',
      'top': kpx(238),
      'white-space': 'nowrap',
      'width': kpx(375 - 44 * 2),
    };
    css.text6 = {
      ...sans_serif_font(),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'color': 'rgba(243,243,242,1.0)',
      'font-size': kpx(14),
      'font-style': 'normal',
      'font-weight': '400',
      'height': kpx(68),
      'left': kpx(35),
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'overflow': 'hidden',
      'position': 'absolute',
      'text-align': 'left',
      'top': kpx(303),
      'width': kpx(308),
    };

    // moreが有効なので高さを大きくする
    if (this.more_active) {
      css.text6.height = this.text6_height;
    }

    css.base6 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'height': `${kpx(14)}`,
      'left': `${kpx(174)}`,
      'position': `absolute`,
      'top': `${kpx(394)}`,
      'width': `${kpx(30)}`,
    };
    css.text7 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
      'font-size': `${kpx(12)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'height': `100%`,
      'left': `${kpx(1)}`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(25)}`,
      'position': `absolute`,
      'text-align': `center`,
      'top': `${kpx(-6)}`,
      'white-space': `nowrap`,
      'width': `100%`,
    };
    css.img2 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'bottom': `${kpx(0)}`,
      'height': `${kpx(1)}`,
      'left': `${kpx(0)}`,
      'position': `absolute`,
      'width': `100%`,
    };

    return m('div', {style: css.base1}, [
      m('div', {style: css.base2}, [
        m('div', {style: css.base3}, [
          m('div', {style: css.base4}, [
            m('div', {style: css.base5}, [
              m('div', {style: css.text1}, shop.category ? shop.category.name: 'その他'),
              m('div', {style: css.text2}, shop.category ? shop.category.name_en: 'others'),
            ]),
          ]),
        ]),
      ]),
      m('div', {style: css.text3}, shop.name),
      m('img', {style: css.img1, src: '/static/app/img/--5@1x.png'}),
      m('div', {style: css.text4}, shop.name_en ? shop.name_en : '未入力'),
      m('div', {style: css.text5}, shop.title ? shop.title : '未入力'),
      m('div', {style: css.text6}, shop.desc ? m('div', {id: 'id_desc'}, shop.desc) : '未入力'),

      // moreボタン
      this.more_active ? '' : m('div', {
        style: css.base6,
        onclick: () => {
          this.more_active = true;
          const desc = document.getElementById('id_desc');
          const desc_height = desc.getClientRects()[0].height;
          const text6_height = desc.parentElement.getClientRects()[0].height;
          const delta = desc_height - text6_height;
          this.text6_height = desc_height + 'px';
          this.base_height = (parseInt(kpx(452).slice(0, -2)) + delta) + 'px';
        },
      }, [
        m('div', { style: css.text7 }, 'more'),
        m('img', {style: css.img2, src: '/static/app/img/--7-6@1x.png'}),
      ]),
    ]);
  }
}

class ShopGallery {
  oninit(vnode) {
    const shop = vnode.attrs.shop;
    const shopphoto_set = shop.shopphoto_set || [];

    this.src_set = shopphoto_set.map((shopphoto) => shopphoto.image);
    if (this.src_set.length <= 0) {
      // 現在は画像がないので先頭だけデザインで入っているダミー画像を入れる
      this.src_set = ['/static/app/img/des-4304@1x.png'];
    }
    this.src_set = this.src_set.map((src) => src ? src : dummy_image());

    this.active_index = 0;
  }

  view(vnode) {
    const shop = vnode.attrs.shop;
    const css = {};
    css.base1 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'rgba(136,118,75,1.0)',
      'flex-shrink': '1',
      'height': kpx(408),
      'margin-top': '0px',
      'position': 'relative',
      'width': kpx(375),
    };
    css.text1 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'color': 'rgba(243,243,242,1.0)',
      'font-family': '\'Tenor Sans\', Helvetica, Arial, serif',
      'font-size': kpx(16),
      'font-style': 'normal',
      'font-weight': '400',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'height': kpx(18),
      'letter-spacing': '0.00px',
      'line-height': kpx(25),
      'position': 'absolute',
      'right': '0px',
      'text-align': 'center',
      'top': kpx(24),
      'white-space': 'nowrap',
      'width': '100%',
    };
    css.base2 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'height': kpx(375 - 60 * 2),
      'left': kpx(60),
      'position': 'absolute',
      'top': kpx(60),
      'width': kpx(375 - 60 * 2),
    };
    css.img1 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'height': `${kpx(31)}`,
      'left': `${kpx(23)}`,
      'position': `absolute`,
      'top': `calc(45.10% - 14px)`,
      'width': `${kpx(16)}`,
    };
    css.img2 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'height': `${kpx(31)}`,
      'position': `absolute`,
      'right': `${kpx(22)}`,
      'top': `calc(45.10% - 14px)`,
      'width': `${kpx(16)}`,
    };
    css.indicator1 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'border': `1px solid rgba(243,243,242,1.0)`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'bottom': `calc(21.09% - 1px)`,
      'height': `${kpx(5)}`,
      'left': `calc(40.00% - 2px)`,
      'position': `absolute`,
      'width': `${kpx(5)}`,
    };
    css.indicator2 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `rgba(243, 243, 242, 1.0)`,
      'bottom': `calc(21.09% - 1px)`,
      'height': `${kpx(5)}`,
      'left': `calc(44.05% - 2px)`,
      'position': `absolute`,
      'width': `${kpx(5)}`,
    };
    css.text2 = {
      ...sans_serif_font(),
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'bottom': `${kpx(30)}`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'font-size': `${kpx(14)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'height': `${kpx(46)}`,
      'left': `${kpx(43)}`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(24)}`,
      'position': `absolute`,
      'text-align': `center`,
      'width': `calc(100.00% - ${kpx(84)})`,
    };

    // インジータを作る
    const indicator_set = [...Array(this.src_set.length).keys()].map(
      (index) => {
        const style = index == this.active_index ?
          {...css.indicator2} :
          {...css.indicator1};
        style.left = `calc(${(50 - (this.src_set.length * 4.05) / 2) + 4.05 * index}% - 2px`;
        return m('div', {style});
      });

    const shopphoto_set = shop.shopphoto_set || [];
    return m('div', {style: css.base1}, [
      m('div', {style: css.text1}, 'GALLERY'),
      m('div', {style: css.base2}, [
        m('ons-carousel', {
          'fullscreen': true,
          'swipeable': true,
          'auto-scroll': true,
          'overscrollable': true,
          'id': 'carousel',
        }, [
          this.src_set.map((src) =>
            m('ons-carousel-item', [
              m('img', {width: '100%', src}),
            ]),
          ),
        ]),
      ]),
      m('img', {
        style: css.img1,
        src: '/static/app/img/---333@1x.png',
        onclick: () => {
          const carousel = document.getElementById('carousel');
          carousel.prev();
          this.active_index = carousel.getActiveIndex();
          m.redraw();
        },
      }),
      m('img', {
        style: css.img2,
        src: '/static/app/img/---332@1x.png',
        onclick: () => {
          const carousel = document.getElementById('carousel');
          carousel.next();
          this.active_index = carousel.getActiveIndex();
          m.redraw();
        },
      }),
      indicator_set,
      m('div', {style: css.text2},
        shopphoto_set[this.active_index] ? shopphoto_set[this.active_index].desc: ''),
    ]);
  }
}

class ShopData {
  view(vnode) {
    const shop = vnode.attrs.shop;
    const css = {};
    css.base1 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      'flex-shrink': '1',
      'height': kpx(316),
      'margin-top': '0px',
      'position': 'relative',
      'width': kpx(375),
    };
    css.base2 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'rgba(69,70,70,1.0)',
      'height': kpx(316),
      'left': '0px',
      'position': 'absolute',
      'top': '0px',
      'width': kpx(375),
    };
    css.text1 = {
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
      'font-size': `${kpx(16)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      'height': `auto`,
      'left': `${kpx(168)}`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(25)}`,
      'position': `absolute`,
      'text-align': `center`,
      'top': `${kpx(40)}`,
      'white-space': `nowrap`,
      'width': `auto`,
    };
    css.text2 = {
      ...sans_serif_font(),
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'font-size': `${kpx(12)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      // 'height': `${kpx(196)}`,
      'height': `auto`,
      'left': `${kpx(64)}`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(16)}`,
      'position': `absolute`,
      'text-align': `left`,
      'top': `${kpx(74)}`,
      'width': `${kpx(54)}`,
    };
    css.text3 = {
      ...sans_serif_font(),
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'font-size': `${kpx(12)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      // 'height': `${kpx(214)}`,
      'height': `auto`,
      'left': `${kpx(141)}`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(16)}`,
      'position': `absolute`,
      'text-align': `left`,
      'top': `${kpx(74)}`,
      'width': `${kpx(207)}`,
    };
    css.base3 = {
      ...sans_serif_font(),
      'pointer-events': `auto`,
      'word-wrap': `break-word`,
      'word-break': `break-word`,
      'color': `rgba(243, 243, 242, 1.0)`,
      'font-size': `${kpx(12)}`,
      'font-style': `normal`,
      'font-weight': `400`,
      'box-sizing': `border-box`,
      'background-color': `transparent`,
      // 'height': `${kpx(196)}`,
      'height': `auto`,
      'left': `${kpx(64)}`,
      'letter-spacing': `${kpx(0.00)}`,
      'line-height': `${kpx(16)}`,
      'position': `absolute`,
      'text-align': `left`,
      'top': `${kpx(74)}`,
      'width': `${kpx(284)}`,
    }
    css.base4 = {
      'margin-bottom': `${kpx(16)}`,
    }
    css.text4 = {
      'display': 'inline-block',
      width: `${kpx(61)}`,
      'line-height': `${kpx(16)}`,
      'vertical-align': `top`,
    }
    css.text5 = {
      'display': 'inline-block',
      width: `${kpx(200)}`,
      'line-height': `${kpx(16)}`,
      'vertical-align': `top`,
    }

    return m('div', {style: css.base1}, [
      m('div', {style: css.base2}, [
        m('div', {style: css.text1}, 'DATA'),
        m('div', {style: css.base3}, [
          m('div', {style: css.base4}, [
            m('div', {style: css.text4}, '電話'),
            m('div', {style: css.text5}, shop.phone ? shop.phone : '---'),
          ]),
          m('div', {style: css.base4}, [
            m('div', {style: css.text4}, [
              '住所',
            ]),
            m('div', {style: css.text5}, shop.address ? shop.address : '---'),
          ]),
          m('div', {style: css.base4}, [
            m('div', {style: css.text4}, [
              '営業時間',
            ]),
            m('div', {style: css.text5}, shop.opening_hours ? shop.opening_hours : '---'),
          ]),
          m('div', {style: css.base4}, [
            m('div', {style: css.text4}, 'URL'),
            m('div', {style: css.text5}, shop.url ? shop.url : '---'),
          ]),
        ]),
      ]),
    ]);
  }
}

export class ShopDetail {
  view(vnode) {
    const shop = vnode.attrs.shop;
    const style = {
      'align-items': 'flex-start',
      'background-color': 'rgba(255,255,255,1.0)',
      'box-sizing': 'border-box',
      'display': 'flex',
      'flex-direction': 'column',
      'justify-content': 'flex-start',
      'overflow': 'hidden',
      'overflow-x': 'hidden',
      'position': 'relative',
      'width': '100%',
      'pointer-events': 'auto',
      'flex-shrink': '0',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
    };
    return m('div', {style}, [
      m(ShopPhoto, {shop: shop}),
      m(ShopInfo, {shop: shop}),
      m(ShopGallery, {shop: shop}),
      m(ShopData, {shop: shop}),
    ]);
  }
}

/* EOF */
