/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import {text_wrap, sans_serif_font, kpx} from './utils.js';

export class Footer {
  view() {
    const style1 = {
      ...text_wrap(),
      'background-color': 'rgba(69,70,70,1.0)',
      'height': kpx(334),
      'width': '100%',
      'position': 'relative',
    };
    const style2 = {
      ...text_wrap(),
      ...sans_serif_font(),
      'font-size': kpx(13),
      'position': 'absolute',
      'color': 'rgba(243,243,242,1.0)',
      'background-color': 'transparent',
      'letter-spacing': '0.00px',
      'line-height': kpx(24),
      'text-align': 'left',
      'white-space': 'nowrap',
    };
    const style3 = {
      ...style2,
      'left': kpx(25),
      'top': kpx(34),
    };
    const style4 = {
      ...style2,
      'left': kpx(100),
      'top': kpx(34),
    };
    const style5 = {
      ...style2,
      'left': kpx(100),
      'top': kpx(64),
    };
    const style6 = {
      ...style2,
      'width': kpx(375 - 50),
      'left': kpx(25),
      'top': kpx(110),
      'border-bottom': 'solid 3px white',
    };
    const style7 = {
      ...style2,
      'width': kpx(375 - 50),
      'height': kpx(120),
      'left': kpx(25),
      'top': kpx(130),
      'overflow-y': 'scroll',
      'white-space': 'normal',
    };
    const style8 = {
      ...style2,
      'width': kpx(375 - 50),
      'left': kpx(25),
      'top': kpx(280),
      'text-align': 'center',
    };
    return m('div', {style: style1}, [
      m('div', {style: style3}, 'ガイド'),
      m('div', {style: style4}, 'ご利用ガイド　＞'),
      m('div', {style: style5}, '特定商取引法に基づく表記　＞'),
      m('div', {style: style6}),
      m('div', {style: style7}, [
        '利用規約', m('br'),
        m('br'),
        '日本橋久松飲食業組合　日本橋料理飲食業組合（以下両組合）とが共同' +
        'で運営する、', , m('br'),
        '「美味しいフォトコンテスト」！', , m('br'),
        m('br'),
        'この利用規約(以下「本利用規約」)は、私達両組合が運営する　' +
        'GoTo商店街参加イベント「美味しいフォトコンテスト」に参加され、' +
        'このサイトをご利用される際にお約束いただくものです。', m('br'),
        '「美味しいフォトコン店スト」イベントは、令和３年３月８日から' +
        '３月１４日までの間に両組合加盟店の有志によって実施されるフォト' +
        'コンテストです。', m('br'),
        m('br'),
        '「美味しいフォトコンテスト」イベント', m('br'),
        '両組合は、「美味しいフォトコンテスト」」（以下、当イベント）に' +
        '参加される、両組合加盟の有志の店舗での飲食をする際に投稿応募する方' +
        'に当イベントシステムを提供します。', m('br'),
        m('br'),
        '「投稿応募できるお写真」', m('br'),
        '当イベントで投稿対象となる写真データは、両組合加盟の有志店舗で有料' +
        '注文し飲食されたお客様の飲食物を対象とします。', m('br'),
        '当イベントでの投稿期間は、令和３年３月８日～令和３年３月１４日まで' +
        'の期間とします。', m('br'),
        '当イベントで投稿された写真データの中は、両組合の審査により６０人の' +
        '写真データを選抜し「美味しいフォトコンテスト」の入賞作品とします。' +
        'この結果は、両組合のホームページで結果を発表します。', m('br'),
        '当イベントへの投稿写真データは、お客様自身が撮影された写真データに限' +
        'ります。', m('br'),
        '当イベントでの写真データ投稿方法は、ご自分のスマートフォンの写真機能' +
        'と店舗に掲出されるＱＲコードを経由して行います。', m('br'),
        '当イベントでの入賞作品は、両組合が行う宣伝活動に利用される事がありま' +
        'す。', m('br'),
        m('br'),
        'また、弊社はInstagram内外で利用者が広告主のコンテンツに対してどのよ' +
        'うなアクションを実行したかを広告主が把握できるよう、広告のパフォー' +
        'マンスに関するレポートを広告主に提供します。 広告主には、例えば、設' +
        '定したオーディエンスについての理解を深めることができるよう、一般的な' +
        '利用者層データや興味・関心の情報が提供されます。 ただし、弊社は、利' +
        '用者の明確な許可を得ずに、利用者を直接特定する情報(氏名やメールアド' +
        'レスなど、それのみで利用者への連絡に使用できる、または利用の身元が特' +
        '定される情報)を共有しません。Instagram広告のしくみについて詳しくは、' +
        'こちらをご覧ください。', m('br'),
        m('br'),
        '当イベント参加者による同意事項', m('br'),
        m('br'),
        '弊社が提供するサービスのご利用にあたり、参加者は、次の事項について、' +
        '両組合に対して同意いただく必要があります。', m('br'),
        '当システムを利用できる方は、法令に従って、法令違反などをおかした事が' +
        'ないこと。', m('br'),
        '過去に有罪判決を受けたことがないこと。', m('br'),
        '他人へのなりすましや不正確な情報の提供は禁止されています。', m('br'),
        '他者へのなりすましは禁止されています。不正な、誤解を招くおそれのある、' +
        'または詐欺的な行為を行うことや、違法または不正な目的で何らかの行為を' +
        '行うことは禁止されています。利用者は、日本国の制定する法令に違反する' +
        '(または、これらに対する他の人の違反を手助けしたり、奨励したりする)こ' +
        'とを行ってはなりません。不正な方法を用いて、アカウントの作成、情報へ' +
        'のアクセス、または情報の取得を試みることは禁止されています。', m('br'),
        '他者の個人情報や秘密情報を許可なく投稿したり、知的財産権などの他者の' +
        '権利を侵害したりする行為(著作権侵害、商標侵害、偽造、海賊品など)は禁' +
        '止されています。', m('br'),
        '両組合が応募参加者のコンテンツの権利の帰属を主張しませんが、当イベン' +
        'トへの応募者はコンテンツを使用するためのライセンスを両組合に無償で付' +
        '与して頂きます。', m('br'),
      ]),
      m('div', {style: style8}, [
        '©2021みんなでつくる美味しいメニュー',
      ]),
    ]);
  }
}

/* EOF */
