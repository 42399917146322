/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { kpx } from './utils.js';
import { CloseIcon } from './close_icon.js';

// 指定した要素(elem)からルートに向かって指定したタグ名(tag_name)の要素を探す
function search_elem_to_root(elem, tag_name) {

  // 要素が無くなった場合は存在しないのでnullを返す
  if (!elem) {
    return null;
  }

  //  要素が指定されたタグ名ならば発見したので、その要素を返す
  if (elem.tagName === tag_name) {
    return elem;
  }

  // 見つからない場合は親に移動して再度検索
  return search_elem_to_root(elem.parentNode, tag_name);
}

export class CloseButton {
  view(vnode) {
    const style = {
      position: 'absolute',
      top: kpx(24),
      left: kpx(330),
      width: kpx(22),
      height: kpx(22),
      background: 'transparent',
      'z-index': 10000,
    };
    return m('div', {
      style,
      onclick: () => {
        // 載っているダイアログを探し、あればそれを非表示にする
        let elem = search_elem_to_root(vnode.dom, 'ONS-MODAL');
        if (elem) {
          elem.hide();
        }
      },
    }, m(CloseIcon, {stroke: vnode.attrs.stroke}));
  }
}

/* EOF */
