/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import {kpx, sans_serif_font} from './utils.js';
import {CloseButton} from './close_button.js';
import {CampaignLogo} from './campaign_logo.js';

export class Campaign {
  view() {
    // 管理しやすいようにスタイルをオブジェクトに纏めておく
    const css = {};
    css.base1 = {
      'text-align': 'left',
      display: 'block',
      'background-color': 'rgba(255,236,71,1.0)',
      opacity: '1.00',
      height: '100%',
      color: 'rgba(69,70,70,1.0)',
    };
    css.base2 = {
      'align-items': 'center',
      'background-color': 'rgba(255,236,71,1.0)',
      'box-sizing': 'border-box',
      display: 'flex',
      'flex-direction': 'column',
      height: 'auto',
      'justify-content': 'flex-start',
      overflow: 'hidden',
      'overflow-x': 'hidden',
      padding: kpx(24) + ' ' + kpx(23),
      position: 'relative',
      width: '100%',
      'pointer-events': 'auto',
      'flex-shrink': '0',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'padding-bottom': kpx(58),
    };
    css.campaign_logo = {
      'margin-top': kpx(4),
      'margin-left': kpx(5),
      position: 'relative',
      width: kpx(206),
      height: kpx(194),
    };
    css.text1 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'align-items': 'flex-start',
      'align-self': 'flex-end',
      'background-color': 'transparent',
      display: 'flex',
      'flex-direction': 'row',
      'flex-shrink': '1',
      'justify-content': 'flex-start',
      'min-width': kpx(213),
      position: 'relative',
      width: 'auto',
      height: kpx(28),
    };
    css.text2 = {
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'align-self': 'flex-end',
      'background-color': 'transparent',
      color: 'rgba(69,70,70,1.0)',
      'flex-shrink': '1',
      'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
      'font-size': kpx(10),
      'font-style': 'normal',
      'font-weight': '400',
      'letter-spacing': kpx(0.00),
      'line-height': kpx(25),
      'min-height': kpx(14),
      'text-align': 'center',
      'white-space': 'nowrap',
      position: 'relative',
      width: kpx(100),
      height: kpx(14),
    };
    css.text3 = {
      ...sans_serif_font(),
      'font-size': kpx(22),
      'font-style': 'normal',
      'font-weight': '400',
      'letter-spacing': kpx(0.00),
      'line-height': kpx(35),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      color: 'rgba(69,70,70,1.0)',
      'flex-shrink': '1',
      'margin-left': kpx(2.0),
      'margin-top': kpx(10),
      'min-height': kpx(70),
      'text-align': 'center',
      position: 'relative',
      width: kpx(271),
      height: kpx(70),
    };
    css.text4 = {
      ...sans_serif_font(),
      'font-size': kpx(14),
      'font-style': 'normal',
      'font-weight': '400',
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      color: 'rgba(69,70,70,1.0)',
      'flex-shrink': '1',
      height: 'auto',
      'margin-left': kpx(3.0),
      'margin-top': kpx(17),
      'min-height': kpx(170),
      position: 'relative',
      'text-align': 'center',
      width: kpx(308),
    };
    css.text5 = {
      ...sans_serif_font(),
      'font-size': kpx(14),
      'font-style': 'normal',
      'font-weight': '400',
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      color: 'rgba(69,70,70,1.0)',
      'flex-shrink': '1',
      height: kpx(22),
      'margin-bottom': kpx(-8),
      'margin-left': kpx(2.5),
      'margin-top': kpx(43),
      'min-height': kpx(14),
      overflow: 'hidden',
      position: 'relative',
      'text-align': 'center',
      'white-space': 'nowrap',
      width: kpx(308),
    };
    css.text6 = {
      ...sans_serif_font(),
      'font-size': kpx(14),
      'font-style': 'normal',
      'font-weight': '400',
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      color: 'rgba(69,70,70,1.0)',
      'flex-shrink': '1',
      height: 'auto',
      'margin-left': kpx(2.0),
      'margin-top': kpx(13),
      // 'min-height': kpx(60),
      position: 'relative',
      'text-align': 'left',
      width: kpx(279),
    };
    css.text7 = {
      ...sans_serif_font(),
      'font-size': kpx(14),
      'font-style': 'normal',
      'font-weight': '400',
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      color: 'rgba(69,70,70,1.0)',
      'flex-shrink': '1',
      height: kpx(22),
      'margin-bottom': kpx(-8),
      'margin-left': kpx(2.5),
      'margin-top': kpx(26),
      'min-height': kpx(14),
      overflow: 'hidden',
      position: 'relative',
      'text-align': 'center',
      'white-space': 'nowrap',
      width: kpx(308),
    };
    css.text8 = {
      ...sans_serif_font(),
      'font-family': `'HiraginoKaku Gothic Std-W4', Helvetica, Arial, serif`,
      'font-size': kpx(14),
      'font-style': 'normal',
      'font-weight': '400',
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'pointer-events': 'auto',
      'word-wrap': 'break-word',
      'word-break': 'break-word',
      'box-sizing': 'border-box',
      'background-color': 'transparent',
      color: 'rgba(69,70,70,1.0)',
      'flex-shrink': '1',
      height: 'auto',
      'margin-left': kpx(2.0),
      'margin-top': kpx(13),
      // 'min-height': kpx(60),
      position: 'relative',
      'text-align': 'left',
      width: kpx(279),
    };
    css.wrapper = {
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      overflowY: 'scroll',
    };

    return m('ons-modal', {id: 'campaign'}, [
      m('.modal__content', {style: css.base1}, [
        m('div', {style: css.wrapper}, [
          m('div', {style: css.base2}, [
            m(CloseButton, {stroke: 'rgba(69,70,70,1.0)'}),
            m('div', {style: css.text1}, [
              m('div', {style: css.text2}, 'CAMPAIGN'),
            ]),
            m('div', {style: css.text3}, [
              '「美味しいフォト」', m('br'),
              'コンテスト開催！', m('br'),
            ]),
            m('div', {style: css.campaign_logo}, [
              m(CampaignLogo, {width: kpx(206), height: kpx(194)}),
            ]),
            m('div', {style: css.text4}, [
              '2021年3月8日から14日までの期間で、「美味しいフォトコンテスト」を実施します。',
              'お写真を撮って頂きたい店舗は', m('br'),
              m('br'),
              '日本橋久松飲食業組合', m('br'),
              '日本橋料理飲食業組合', m('br'),
              m('br'),
              'に加盟しているお店で、「美味しいフォトコンテスト」のポスターが掲出されているお店でご参加ください。',
            ]),
            m('div', {style: css.text5}, [
              '［　応募方法　］',
            ]),
            m('div', {style: css.text6}), [
              m('ol', [
                m('li', [
                  '店舗テーブルに置かれた、キャンペーンステッカーからスマホで' +
                  'QRコードを読み取り、撮影された料理写真を所定の手順で、投稿' +
                  'ください。',
                ]),
                m('li', [
                  '撮影・投稿された料理写真は、確認の上キャンペーンサイトで掲' +
                  '示され、組合による投票により、当選者60名を決めさせて頂きま' +
                  'す。当選者の方には、応募時に記入されたメールアドレスにご連' +
                  '絡させて頂きます。',
                ]),
                m('li', [
                  'ご自分で撮影されたお写真でご参加ください。',
                ])
              ]),
              '皆様の沢山のご投稿をお待ちしております。',
            ],
            m('div', {style: css.text7}), [
              '［　応募要項　］',
            ],
            m('div', {style: css.text8}), [
              '応募期間', m('br'),
              '令和３年３月８日（月）～令和３年３月１４日（日）', m('br'),
              m('br'),
              '応募条件', m('br'),
              m('ol', [
                m('li', [
                  '日本橋久松飲食業組合、日本橋料理飲食業組合加盟の参加店舗の' +
                  'メニュー商品をご注文頂き、そのご注文料理の撮影写真であること。',
                ]),
                m('li', [
                  '撮影された料理写真は、現行メニューに継続提供されていれば、' +
                  '過去に撮影したものでも応募頂けます。',
                ]),
                m('li', [
                  '応募頂くお写真は、必ずご自身、或いはご自身のスマートフォン' +
                  'や携帯電話で撮影されたお写真を、ご投稿ください。',
                ]),
                m('li', [
                  '応募頂くお写真は、必ず当イベント参加店舗の料理写真として下さい。',
                ]),
                m('li', [
                  '応募されたお写真が、公序良俗に触れる場合、他者の著作権に' +
                  '触れる場合は、日本国憲法の範疇で罰せられる事があります。',
                ]),
              ]),
              m('div', {
                style: {
                  'text-align': 'left',
                  'margin-bottom': '1rem',
                  'margin-top': '1rem',
                  width: '100%',
                }
              }, [
                '選考方法',
              ]),
              m('div', [
                '令和３年３月末頃を目途に、両組合の審査により応募作品の中' +
                'から抽選で60名様に、両組合加盟店厳選の名店の味を進呈します。',
              ]),
              m('div', {
                style: {
                  'text-align': 'left',
                  'margin-bottom': '1rem',
                  'margin-top': '1rem',
                  width: '100%',
                }
              }, [
                '作品掲載について',
              ]),
              m('ol', [
                m('li', [
                  '応募投稿されたお写真は、「美味しいフォトコンテスト」WEBに' +
                  '掲載し公開させて頂きます。',
                ]),
                m('li', [
                  '掲載期間は、令和3年3月末頃より1か月間とします。',
                ]),
                m('li', [
                  '掲載のご連絡は、掲載をもって代えさせて頂きます。',
                ]),

              ]),
              m('div', {
                style: {
                  'text-align': 'right',
                  'width': '100%',
                }
              }, [
                '「美味しいフォトコンテスト」事務局',
              ]),
            ],
          ]),
        ]),
      ]),
    ]);
  }
}

/* EOF */
