/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';

export class SearchIcon {
  view() {
    return m('svg', {
      'xmlns': 'http://www.w3.org/2000/svg',
      'width': '20.354', 'height': '20.354',
      'viewBox': '0 0 20.354 20.354',
    }, [
      m('path', {
        'id': 'パス_334',
        'data-name': 'パス 334',
        'd': 'M56,35a9,9,0,1,1-9-9A9,9,0,0,1,56,35Zm1.5,10.5L53,41',
        'transform': 'translate(-37.5 -25.5)',
        'fill': 'none',
        'stroke': '#231815',
        'stroke-miterlimit': '10',
        'stroke-width': '1',
      }),
    ]);
  }
}

/* EOF */
