/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { Page } from 'mithril-onsenui';
import { Topbar } from './topbar.js';
import { Header } from './header.js';
import { Shops } from './shops.js';
import { UserSnap } from './user_snap.js';
import { Posts } from './posts.js';
import { Footer } from './footer.js';
import { Loading } from './loading.js';
import { About } from './about.js';
import { About2 } from './about2.js';
import { Campaign } from './campaign.js';
import { get_headers, dummy_image } from './utils.js';
import { SearchResults } from './search_results.js';

export class Top {
  constructor() {
    window.loaded = false;
    this.shop_panels = [];
    this.post_panels = [];
    this.kind = null;
    this.shop_limit = 50;
    this.post_limit = 18;
  }

  oninit() {
    this.load_shops().then(() => {
      return this.load_posts();
    }).then(() => {
      window.loaded = true;
    });
  }

  load_shops() {
    const headers = get_headers();
    let url = `/api/panels/shops/?limit=${this.shop_limit}`;
    if (this.kind === 'おすすめ') {
      url = url + '&recommend=G'
    }
    else if (this.kind === '日本橋') {
      url = url + '&area=日本橋';
    }
    else if (this.kind === '人形町') {
      url = url + '&area=人形町';
    }
    url = encodeURI(url)
    return m.request({method: 'GET', url, headers}).
      then((res) => {
        this.shop_panels = res;

        // FIXME:まだデータが入っていないので仮の画像を補う
        for (const panel of this.shop_panels) {
          if (panel.kind === 'S') {
            for (const shop_photo of panel.shop.shopphoto_set) {
              if (!shop_photo.image) {
                shop_photo.image = dummy_image();
              }
            }
          }
        }
      });
  }

  load_posts() {
    const headers = get_headers();
    const url = `/api/panels/posts/?limit=${this.post_limit}`;
    return m.request({method: 'GET', url, headers}).
      then((res) => {
        this.post_panels = res;

        // FIXME:まだデータが入っていないので仮の画像を補う
        for (const panel of this.post_panels) {
          if (panel.kind === 'h') {
            if (!panel.post_photo || !panel.post_photo.image) {
              panel.post_photo = {image: dummy_image()};
            }
          }
        }
      });
  }

  filter(kind) {
    this.kind = kind;

    window.loaded = false;
    this.load_shops().then(() => {
      window.loaded = true;
    })
  }

  more_shop() {
    this.shop_limit += 50;

    window.loaded = false;
    this.load_shops().then(() => {
      window.loaded = true;
    })
  }

  more_post() {
    this.post_limit += 18;

    window.loaded = false;
    this.load_posts().then(() => {
      window.loaded = true;
    })
  }

  view(vnode) {
    return m('div', [
      m(Page, [
        m(Topbar),
        m('.page__background', {
          style: {
            top: '6rem', 'background-color': 'black'
          }
        }),
        m('.page__content', {style: {top: '6rem'}}, [
          m(Header, {top: this}),
          m(Shops, {top: this, shop_panels: this.shop_panels}),
          m(UserSnap),
          m(Posts, {top: this, post_panels: this.post_panels}),
          m(Footer),
        ]),
        m(Loading, {loaded: window.loaded}),
      ]),
      m(About),
      m(About2),
      m(Campaign),
      m(SearchResults),
    ]);
  }
}

/* EOF */
