/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { flex_center, sans_serif_font } from './utils.js';
import { LogoIcon } from './logo_icon.js';
import { MenuIcon} from './menu_icon.js';

// トップバー
export class Topbar {
  view() {
    const css = {};
    css.base = {
      height: '6rem',
      color: 'rgba(11,3,56,1.0)',
    };
    css.text1 = {
      ...sans_serif_font(),
      "font-size": "12px",
      "letter-spacing": "0.00px",
      "line-height": "25px",
      "pointer-events": "auto",
      "word-wrap": "break-word",
      "word-break": "break-word",
      "box-sizing": "border-box",
      "background-color": "transparent",
      "bottom": "calc(49.31% - 7px)",
      "color": "rgba(11,3,56,1.0)",
      "height": "14px",
      "left": "14px",
      "position": "absolute",
      "text-align": "left",
      "white-space": "nowrap",
      "width": "86px"
    };
    css.text2 = {
      ...sans_serif_font(),
      "font-size": "14px",
      "letter-spacing": "0.00px",
      "line-height": "25px",
      "pointer-events": "auto",
      "word-wrap": "break-word",
      "word-break": "break-word",
      "box-sizing": "border-box",
      "background-color": "transparent",
      "color": "rgba(11,3,56,1.0)",
      "height": "16px",
      "left": "14px",
      "position": "absolute",
      "text-align": "left",
      "top": "calc(22.14% - 4px)",
      "white-space": "nowrap",
      "width": "100px"
    };
    css.text3 = {
      ...sans_serif_font(),
      "letter-spacing": "0.00px",
      "line-height": "25px",
      "font-size": "12px",
      "pointer-events": "auto",
      "word-wrap": "break-word",
      "word-break": "break-word",
      "box-sizing": "border-box",
      "background-color": "transparent",
      "bottom": "calc(28.47% - 4px)",
      "color": "rgba(11,3,56,1.0)",
      "height": "14px",
      "left": "14px",
      "position": "absolute",
      "text-align": "left",
      "white-space": "nowrap",
      "width": "98px"
    };

    return m('ons-toolbar', {'style': css.base}, [
      m('div', {'class': 'left'}, [
        m('div', {style: css.text1}, 'みんなでつくる'),
        m('div', {style: css.text2}, '日本橋・人形町'),
        m('div', {style: css.text3}, '美味しいメニュー'),
      ]),
      m('div', {'class': 'center'}, [
        m('div', {
          style: {
            ...flex_center(),
            height: '6rem'
          }
        }, [
          m(LogoIcon, {width: '60px', height: '60px', stroke: '#000'})
        ])
      ]),
      m('div', {
        'class': 'right',
        onclick: () => {
          document.getElementById('about').show();
        },
      }, [
        m('div', {
          style: {
            ...flex_center(),
            height: '6rem'
          }
        }, [
          m(MenuIcon, {width: '24px', height: '16px'})
        ])
      ]),
    ]);
  }
}

/* EOF */
