/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { kpx, text_wrap, serif_font, sans_serif_font } from './utils.js';
import { PostIcon } from './post_icon.js';

export class Panel {
  view(vnode) {
    const panel = vnode.attrs.panel;
    const kind = panel.kind;
    const style = {
      height: 'calc(1 / 3 * 100vw)',
      'line-height': 'calc(1 / 3 * 100vw)',
      'text-align': 'center',
      margin: '0.5px',
      overflow: 'hidden',
    };

    if (panel.size == 4) {
      style['grid-column-end'] = 'span 2';
      style['grid-row-end'] = 'span 2';
      style['margin'] = '0.25px';
      style['height'] = 'calc(2 / 3 * 100vw)';
    }

    if (kind == 'C') {
      const category = panel.category;
      style['background-color'] = category.color;
      const style1 = {
        ...text_wrap(),
        ...sans_serif_font(),
        'background-color': 'transparent',
        'color': 'white',
        'letter-spacing': '0.00px',
        'line-height': kpx(25),
        'text-align': 'center',
        'white-space': 'nowrap',
      };
      return m('div', {style}, [
        m('div', {
          style: {
            ...style1,
            'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
            'font-size': kpx(12),
            'margin-top': kpx(216 - 169 - 8),
          },
        }, category.name_en),
        m('div', {
          style: {
            ...style1,
            'font-size': kpx(15),
            'margin-top': kpx(-9),
          },
        }, category.name),
      ]);
    }

    if (kind == 'S') {
      const shop = panel.shop;
      const shopphoto_set = shop.shopphoto_set;
      let src = shopphoto_set[0] ? shopphoto_set[0].image : null;
      if (!src) {
        src = 'https://placehold.jp/150x150.png?text=準備中';
      }
      if (vnode.attrs.selected) {
        style['background-color'] = 'white';

        const css = {};
        css.text1 = {
          ...text_wrap(),
          ...sans_serif_font(),
          'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
          "font-size": kpx(panel.size === 4 ? 14 : 7),
          "letter-spacing": kpx(0.00),
          "line-height": kpx(panel.size === 4 ? 18 : 10),
          "text-align": "center",
          "white-space": "nowrap",
          "background-color": "transparent",
          "color": shop.category.color,
          "margin-top": kpx(panel.size === 4 ? 48 : 24),
        };
        css.text2 = {
          ...css.text1,
          ...sans_serif_font(),
          "font-size": kpx(panel.size === 4 ? 18 : 9),
          "line-height": kpx(panel.size === 4 ? 20 : 10),
          "margin-top": kpx(panel.size === 4 ? 0 : 0),
        };
        css.text3 = {
          ...css.text1,
          ...sans_serif_font(),
          "color": "rgba(11,3,56,1.0)",
          "font-size": kpx(panel.size === 4 ? 25 : 12.5),
          "line-height": kpx(panel.size === 4 ? 20 : 10),
          "margin-top": kpx(panel.size === 4 ? 30 : 15),
        };
        css.text4 = {
          ...css.text1,
          ...sans_serif_font(),
          width: '50%',
          'margin-left': 'auto',
          'margin-right': 'auto',
          'color': 'white',
          'background-color': shop.category.color,
        };
        css.text5 = {
          ...css.text1,
          ...sans_serif_font(),
          'color': 'white',
          "font-size": kpx(panel.size === 4 ? 16 : 8),
          "line-height": kpx(panel.size === 4 ? 20 : 10),
        };

        return m('div', {
          style,
          panel,
          onclick: (e) => vnode.attrs.shops.onclick(panel)
        }, [
          m('div', {
            style: {
              height: panel.size === 4 ? 'calc(2 / 3 * 100vw)': 'calc(1 / 3 * 100vw)'
            }
          }, [
            m('div', {style: css.text1}, shop.category.name_en),
            m('div', {style: css.text2}, shop.category.name),
            m('div', {style: css.text3}, shop.name),
            m('div', {style: css.text4}, [
              m('div', {style: css.text5}, '詳細はこちら'),
            ])
          ])
        ]);
      }
      return m('div', {
        style,
        panel,
        onclick: (e) => vnode.attrs.shops.onclick(panel)
      }, [
        m('img', {src, width: '100%'}),
      ]);
    }

    if (kind == 'A') {
      style['background-color'] = 'rgb(10,5,53)';
      const style1 = {
        ...text_wrap(),
        ...sans_serif_font(),
        'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
        'background-color': 'transparent',
        'color': 'white',
        'letter-spacing': '0.00px',
        'line-height': kpx(25),
        'text-align': 'center',
        'white-space': 'nowrap',
      };
      return m('div', {
        style, onclick: () => {
          document.getElementById('about2').show();
        },
      }, [
        m('div', {
          style: {
            ...style1,
            ...sans_serif_font(),
            'margin-top': kpx(216 - 169 - 8),
            'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
            'font-size': kpx(12),
          },
        }, 'ABOUT'),
        m('div', {
          style: {
            ...style1,
            ...sans_serif_font(),
            'font-size': kpx(15),
            'margin-top': kpx(-9),
          },
        }, 'どんなサイト?'),
      ]);
    }

    if (kind == 'M') {
      style['background-color'] = 'rgb(252,236,103)';
      const style1 = {
        ...text_wrap(),
        ...sans_serif_font(),
        'background-color': 'transparent',
        'color': 'black',
        'letter-spacing': '0.00px',
        'line-height': kpx(25),
        'text-align': 'center',
        'white-space': 'nowrap',
      };
      return m('div', {
        style,
        onclick: () => document.getElementById('campaign').show(),
      }, [
        m('div', {
          style: {
            ...style1,
            'margin-top': kpx(216 - 169 - 8),
            'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
            'font-size': kpx(12),
          },
        }, 'CAMPAIGN'),
        m('div', {
          style: {
            ...style1,
            'font-weight': '800',
            'margin-top': kpx(-9),
            'font-size': kpx(14),
          },
        }, '「美味しいフォト」'),
        m('div', {
          style: {
            ...style1,
            'font-weight': '800',
            'margin-top': kpx(-9),
            'font-size': kpx(14),
          },
        }, 'コンテスト開催！'),
      ]);
    }

    if (kind == 'o') {
      style['background-color'] = 'rgb(10,5,53)';
      return m('div', {style}, [
        m('div', {
          style: {
            ...text_wrap(),
            ...sans_serif_font(),
            'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
            'margin-top': kpx(216 - 169),
            'font-size': kpx(12),
            'background-color': 'transparent',
            'letter-spacing': '0.00px',
            'line-height': kpx(25),
            'text-align': 'center',
            'white-space': 'nowrap',
            'color': 'white',
            'text-decoration': 'underline',
          },
          onclick: () => vnode.attrs.top.more_shop(),
        }, 'more'),
      ]);
    }

    if (kind == 'H') {
      style['background-color'] = 'rgb(10,5,53)';
      const style1 = {
        ...text_wrap(),
        ...sans_serif_font(),
        'background-color': 'transparent',
        'color': 'white',
        'letter-spacing': '0.00px',
        'line-height': kpx(25),
        'text-align': 'center',
        'white-space': 'nowrap',
      };
      return m('div', {style}, [
        m('div', {
          style: {
            ...style1,
            'margin-top': kpx(216 - 169 - 8),
          },
        }, m(PostIcon)),
        m('div', {
          style: {
            ...style1,
            'font-weight': '800',
            'margin-top': kpx(-9),
            'font-size': kpx(14),
          },
        }, '投稿は'),
        m('div', {
          style: {
            ...style1,
            'font-weight': '800',
            'margin-top': kpx(-9),
            'font-size': kpx(14),
          },
        }, 'こちらから！'),
      ]);
    }

    if (kind == 'h') {
      const post_photo = panel.post_photo;
      const src = post_photo && post_photo.image ?
        post_photo.image : 'https://placehold.jp/150x150.png?text=準備中';
      return m('div', {style}, [
        m('img', {src, width: '100%'}),
      ]);
    }

    if (kind == 'O') {
      style['grid-column-end'] = 'span 3';
      style['grid-row-end'] = 'span 2';
      style['margin'] = '0.25px';
      style['background-color'] = 'rgb(10,5,53)';
      return m('div', {style}, [
        m('div', {
          style: {
            ...text_wrap(),
            ...sans_serif_font(),
            'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
            'margin-top': kpx(216 - 169),
            'font-size': kpx(12),
            'background-color': 'transparent',
            'letter-spacing': '0.00px',
            'line-height': kpx(25),
            'text-align': 'center',
            'white-space': 'nowrap',
            'color': 'white',
            'text-decoration': 'underline',
          },
          onclick: () => vnode.attrs.top.more_post(),
        }, 'more'),
      ]);
    }

    if (kind == 'P') {
      style['background-color'] = '#0b0338';
      style['color'] = 'white';
      const style1 = {
        ...text_wrap(),
        ...sans_serif_font(),
        'background-color': 'transparent',
        'letter-spacing': '0.00px',
        'line-height': kpx(25),
        'text-align': 'center',
        'white-space': 'nowrap',
      };
      return m('div', {style}, [
        m('div', {
          style: {
            ...style1,
            'margin-top': kpx(216 - 169 - 8),
            'font-family': `'Tenor Sans', Helvetica, Arial, serif`,
            'font-size': kpx(12),
          },
        }, 'POST'),
        m('div', {
          style: {
            ...style1,
            'font-weight': '800',
            'margin-top': kpx(-9),
            'font-size': kpx(14),
          },
        }, 'お店の写真を'),
        m('div', {
          style: {
            ...style1,
            'font-weight': '800',
            'margin-top': kpx(-9),
            'font-size': kpx(14),
          },
        }, '投稿しよう'),
      ]);
    }

    // パディングならばダミーを表示
    if (panel.kind === '') {
      style['background-color'] = 'lightgray';
      return m('div', {style}, panel.kind);
    }

    return m('div', {style}, panel.kind);
  }
}

/* EOF */
