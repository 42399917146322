/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { text_wrap, kpx, serif_font, sans_serif_font } from './utils.js';
import { CloseButton } from './close_button.js';
import { LogoIcon } from './logo_icon.js';

export class About2 {
  view() {
    // 管理しやすいようにスタイルをオブジェクトに纏めておく
    const css = {};
    css.base = {
      'text-align': 'left',
      'display': 'block',
      'background-color': 'rgba(11,3,56,1.0)',
      'opacity': '0.96',
      'height': '100%',
    };
    css.logo_icon = {
      ...text_wrap(),
      'background-color': 'transparent',
      position: 'absolute',
      height: kpx(60),
      left: kpx(158),
      top: kpx(62),
      width: kpx(60),
    };
    css.text1 = {
      ...text_wrap(),
      ...serif_font(),
      'font-size': kpx(12),
      color: 'rgba(243,243,242,1.0)',
      'letter-spacing': '0.00px',
      'line-height': '25px',
      'text-align': 'center',
      'white-space': 'nowrap',
      position: 'absolute',
      top: kpx(128),
      left: kpx(98),
      width: 'auto',
      height: 'auto',
    };
    css.text2 = {
      ...text_wrap(),
      ...serif_font(),
      'font-size': kpx(22),
      'letter-spacing': '0.00px',
      'line-height': kpx(35),
      'text-align': 'center',
      'background-color': 'transparent',
      color: 'rgba(243,243,242,1.0)',
      position: 'absolute',
      top: kpx(174),
      left: kpx(0),
      width: kpx(375),
      height: 'auto',
    };
    css.text3 = {
      ...text_wrap(),
      ...serif_font(),
      'font-size': kpx(14),
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'text-align': 'center',
      overflow: 'hidden',
      color: 'rgba(243,243,242,1.0)',
      position: 'absolute',
      top: kpx(320),
      width: kpx(308),
      height: kpx(154),
      left: kpx(35),
    };
    css.text4 = {
      ...text_wrap(),
      ...serif_font(),
      'font-size': kpx(14),
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'text-align': 'center',
      'background-color': 'transparent',
      color: 'rgba(243,243,242,1.0)',
      position: 'absolute',
      top: kpx(509),
      left: kpx(35),
      width: kpx(308),
      height: 'auto',
    };
    css.text5 = {
      ...text_wrap(),
      'background-color': 'rgba(243,243,242,1.0)',
      position: 'absolute',
      top: kpx(697),
      left: kpx(41),
      width: kpx(281),
      height: kpx(53),
    };
    css.text6 = {
      ...text_wrap(),
      ...sans_serif_font(),
      'font-size': kpx(14),
      'letter-spacing': kpx(0.00),
      'line-height': kpx(33),
      'text-align': 'center',
      'white-space': 'nowrap',
      color: 'rgba(69,70,70,1.0)',
      position: 'absolute',
      top: kpx(708),
      left: kpx(112),
      width: 'auto',
      height: 'auto',
    };
    css.text7 = {
      ...text_wrap(),
      ...sans_serif_font(),
      'font-size': kpx(14),
      'letter-spacing': kpx(1.05),
      'line-height': kpx(22),
      'text-align': 'center',
      'background-color': 'transparent',
      color: 'rgba(243,243,242,1.0)',
      position: 'absolute',
      top: kpx(790),
      left: kpx(35),
      width: kpx(308),
      height: 'auto',
      'padding-bottom': kpx(58),
    };
    css.wrapper = {
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      overflowY: 'scroll',
    }

    return m('ons-modal', {id: 'about2'}, [
      m('.modal__content', {style: css.base}, [
        m('div', {style: css.wrapper}, [
          m(CloseButton),
          m('div', {style: css.logo_icon}, [
            m(LogoIcon, {width: kpx(60), height: kpx(60)}),
          ]),
          m('div', {style: css.text1}, 'みんなでつくる美味しいメニュー'),
          m('div', {style: css.text2}, [
            '「江戸時代」からはじまった', m('br'),
            '日本人のソウルフードが沢山ある町', m('br'),
            '日本橋・人形町界隈',
          ]),
          m('div', {style: css.text3}, [
            '「日本の美味しい」が凝縮されている日本橋エリアの飲食店は、' +
            '平和な時代が長く続いた江戸時代に発達しました。' +
            '以降、明治の西洋の味との出会いや昭和のアメリカの味の流入との融合も行い、' +
            '300年にも及ぶ「暖簾と味」に「町の雰囲気」を守り続けています。',
          ]),
          m('div', {style: css.text4}, [
            'コロナ感染症対策を行う結果、' +
            '時間短縮営業や休業をせざるを得ない多くの飲食店にとっては、' +
            '厳しい時代が続いていますが、' +
            '日本橋エリアの飲食店は元気に頑張って収束後の本格再開に向けて準備を続けています。' +
            'こんな時期にも、御出で下さるお客様には心より御礼申し上げます。' +
            '毎度有難うございます。',
          ]),
          m('div', {style: css.text5}),
          m('div', {style: css.text6}, [
            '「美味しさ」を守る姿',
          ]),
          m('div', {style: css.text7}, [
            'そして皆様に、お願いがございます。' +
            '私たち、日本橋久松飲食業組合と日本橋料理飲食業組合加盟の店で、' +
            'お食事をお楽しみ頂く際に、ご注文頂いたそのメニューがお気に入りの際には、' +
            'お持ちのスマートフォンで、お写真をお撮り頂き所定の方法でお写真をご投稿ください。' +
            '日本中・世界中のお客様に日本橋の「やる気」と「美味しそう！」を伝えて頂ければと存じます。', m('br'),
            m('br'),
            '投稿した頂いたお客様の中から抽選で60名様に、両組合加盟の名店の味を進呈させて頂きます。',
          ]),
        ]),
      ]),
    ]);
  }
}
