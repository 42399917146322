/* -*- mode:js; coding:utf-8; -*- */

// デザインで指示されているピクセル数から現在の縮尺に合わせたピクセル数を返す
export function kpx(v) {
  const k = window.innerWidth / 375;
  return k * v + 'px';
}

// 標準の明朝体フォントを使うCSSオブジェクトを返す
export function serif_font() {
  return {
    'font-family': `'Hiragino Mincho ProN', 'Noto Serif JP', serif`,
    'font-size': kpx(15),
    'font-style': 'normal',
    'font-weight': '400',
  };
}

// 標準のゴシック体フォントを使うCSSオブジェクトを返す
export function sans_serif_font() {
  return {
    'font-family': `'Hiragino Kaku Gothic ProN', 'Noto Sans', sans-serif`,
    'font-size': kpx(15),
    'font-style': 'normal',
    'font-weight': '400',
  };
}

// 中央に文字を配置する時に使うCSSオブジェクトを返す
export function flex_center() {
  return {
    'display': 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  };
}

// テキストを配置する時の基本的なCSSオブジェクトを返す
export function text_wrap() {
  return {
    'pointer-events': 'auto',
    'word-wrap': 'break-word',
    'word-break': 'break-word',
    'box-sizing': 'border-box',
  };
}

export function get_headers() {
  return {
    'Authorization': 'Token 85d61cf7dc789ae0890c96e161efa0af9a0621a4',
  };
}

export function dummy_image() {
  const images = [
    'img/-mg-0559@1x.png',
    'img/des-4407@1x.png',
    'img/des-4322@1x.png',
    'img/-18d2644@1x.png',
    'img/-dsc4159-5@1x.png',
    'img/-dsc3370-3@1x.png',
    'img/-mg-1154-1@1x.png',
    'img/-mka2458@1x.png',
    'img/sushimasa-0177-1@1x.png',
    'img/-dsc2621@1x.png',
    'img/-18d2749@1x.png',
    'img/-mka0634-1@1x.png',
    'img/-mgm0074-1@1x.png',
    'img/-18d2473@1x.png',
    'img/-dsc2695@1x.png',
    'img/-msa4252@1x.png',
    'img/-18d3222@1x.png',
    'img/-18d3352@1x.png',
    'img/20180512----------------18d2189@1x.png',
    'img/naniwa-0110@1x.png',
    'img/-18d2383@1x.png',
    'img/-mg-2387@1x.png',
    'img/des-4435@1x.png',
    'img/nd-350dpi-honban-1736-2@1x.png',
    'img/-dsc4150@1x.png',
    'img/-mg-0547@1x.png',
    'img/sanei-0063@1x.png',
    'img/-dsc2682@1x.png',
    'img/-mgl0183-1@1x.png',
    'img/-msa5614@1x.png',
    'img/-dsc2662@1x.png',
    'img/-e7a6099-2@1x.png',
    'img/-dsc3370-1@1x.png',
    'img/-msa4191@1x.png',
    'img/-dsc4159-1@1x.png',
    'img/-mka2416@1x.png',
    'img/des-4413@1x.png',
    'img/-mka2388@1x.png',
    'img/images-2-1@1x.png',
    'img/-mgl0089-1@1x.png',
    'img/images-3-1@1x.png',
    'img/unnamed-2@1x.png',
    'img/14a3ad57-1@1x.png',
    'img/images-5-1@1x.png',
    'img/100905ga10000100-1@1x.png',
    'img/article-art002867-2-1@1x.png',
    'img/images-3-1@1x.png',
    'img/l3676920004672216-1@1x.png',
    'img/10133299987-1@1x.png',
    'img/ba71cb96-1c90-4196-b6ff-ca05b9575091-1@1x.png',
    'img/images-1-1@1x.png',
    'img/images-4-1@1x.png',
    'img/unnamed-1-1@1x.png',
    'img/images-5-1@1x.png',
    'img/des-4304@1x.png',
  ];
  // return 'https://placehold.jp/150x150.png?text=準備中';
  const index = Math.floor(images.length * Math.random());
  return '/static/app/' + images[index];
}

/* EOF */
