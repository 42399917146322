/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';
import { Panel } from './panel.js';

export class Shops {

  oninit(vnode) {
    this.selected = null;
  }

  onclick(panel) {
    if (this.selected === panel) {
      document.location = `/app/shop/${panel.shop.ulid}/`;
    }
    else {
      this.selected = panel;
    }
  }

  view(vnode) {
    const style = {
      display: 'grid',
      'grid-template-columns': 'calc(1 / 3 * 100vw) calc(1 / 3 * 100vw) calc(1 / 3 * 100vw)',
    };
    const top = vnode.attrs.top;
    const shop_panels = vnode.attrs.shop_panels;
    const size = shop_panels.map((s) => s.size).reduce((a, v) => a + v, 0);
    const pad_num = size % 3 == 0 ? 0 : 3 - size % 3;
    const padding = [...Array(pad_num).keys()].map(() => {
      return {kind: '', size: 1};
    });
    return m('div', {style}, [
      shop_panels.map((panel) => {
        return m(Panel, {panel, top, shops: this, selected: panel===this.selected});
      }),
      padding.map((panel) => {
        return m(Panel, {panel});
      }),
    ]);
  }
}

/* EOF */
