import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.min.css';


import m from 'mithril';
import { ShopPage } from './shop_page.js';
import { Top } from './top.js';


if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

// 組み込む要素を取得
const root = document.getElementById('root');

// 問い合わせ文字列を取得
const qs = new URLSearchParams(document.location.search);

// 問い合わせ文字列のコマンドを取得
const cmd = qs.get('cmd');

if (cmd === 'shop') {
  m.mount(root, ShopPage);
}
else {
  m.mount(root, Top);
}

window.onresize = () => m.redraw();

// EOF
