/* -*- mode:js; coding:utf-8; -*- */

import m from 'mithril';

export class LogoIcon {
  view(vnode) {
    const width = vnode.attrs.width ? vnode.attrs.width : 61.5;
    const height = vnode.attrs.height ? vnode.attrs.height : 61.5;
    const stroke = vnode.attrs.stroke ? vnode.attrs.stroke : '#f3f3f2';
    return m('svg', {
        'xmlns': 'http://www.w3.org/2000/svg',
        'width': `${width}`,
        'height': `${height}`,
        'viewBox': '0 0 61.5 61.5',
      },
      m('g', {
        'id': 'グループ_440',
        'data-name': 'グループ 440',
        'transform': 'translate(-260.803 -174.124)',
      }, [
        m('circle', {
          'id': '楕円形_19',
          'data-name': '楕円形 19',
          'cx': '30',
          'cy': '30',
          'r': '30',
          'transform': 'translate(261.553 174.874)',
          'fill': 'none',
          'stroke': stroke,
          'stroke-miterlimit': '10',
          'stroke-width': '1.5',
        }),
        m('path', {
          'id': 'パス_339',
          'data-name': 'パス 339',
          'd': 'M267.73,223.136V186.6l47.593,36.532V186.569',
          'fill': 'none',
          'stroke': stroke,
          'stroke-linejoin': 'round',
          'stroke-width': '1.5',
        }),
        m('path', {
          'id': 'パス_340',
          'data-name': 'パス 340',
          'd': 'M278.2,231.736V178.012l26.658,53.724V178',
          'fill': 'none',
          'stroke': stroke,
          'stroke-linejoin': 'round',
          'stroke-width': '1.5',
        }),
      ]),
    );
  }
}

/* EOF */
