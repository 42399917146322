/* -*- mode:js; coding:utf-8; -*- */
/* global m */

import m from 'mithril';
import {kpx, text_wrap, flex_center, serif_font} from './utils.js';

// ヘッダー
export class Header {
  view(vnode) {
    const style1 = {
      'display': 'grid',
      'background-color': 'rgba(11,3,56,1.0)',
      'height': kpx(84),
      'width': '100%',
      'grid-template-columns': '25% 25% 25% 25%',
    };
    const style2 = {
      'position': 'relative',
      ...text_wrap(),
      ...flex_center(),
      ...serif_font(),
      'background-color': 'rgba(11,3,56,1.0)',
      'color': 'white',
      'height': kpx(84),
      'writing-mode': 'vertical-lr',
    };
    const style3 = {
      ...style2,
      'border-right': 'solid 2px white',
    };
    const style4 = {
      position: 'absolute',
      right: '-3px',
      top: 0,
      width: kpx(15),
      height: kpx(15),
      backgroundColor: 'rgba(11,3,56,1.0)',
    };
    return m('div', {style: style1}, [
      m('div', {
        style: style3, onclick: () => {
          vnode.attrs.top.filter('おすすめ');
        },
      }, [
        m('div', {style: style4}),
        'おすすめ',
      ]),
      m('div', {
        style: style3, onclick: () => {
          vnode.attrs.top.filter('日本橋');
        },
      }, [
        m('div', {style: style4}),
        '日本橋',
      ]),
      m('div', {
        style: style3, onclick: () => {
          vnode.attrs.top.filter('人形町');
        },
      }, [
        m('div', {style: style4}),
        '人形町',
      ]),
      m('div', {
        style: style2, onclick: () => {
          vnode.attrs.top.filter('すべて');
        },
      }, [
        'すべて',
      ]),
    ]);
  }
}

/* EOF */
